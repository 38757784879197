import styled from "@emotion/styled"
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { getProductSimpleList } from "apis/productAPI"
import SearchInput from "components/Search/SearchBar/SearchInput"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { PaginationParamsType } from "types/PageType"
import { ProductStatusCode, ProductType } from "types/ProductType"
import { currencyFormat } from "utils/NumberUtils"
import { toDateStr, toTimeStr } from "../../utils/datetimeUtil"
import { uniqBy } from "lodash"
import SearchBar from "../Search/SearchBar"

const FETCH_LIST_COUNT = 25

const searchMenuList: { value: string; title: string }[] = [
  { value: "id", title: "상품번호" },
  { value: "detailModelTitle", title: "모델명" },
  { value: "refNo", title: "Full Ref No." },
]

const ProductSelectPopup: React.FunctionComponent<{
  isSearchable?: boolean
  isMultiSelectable?: boolean
  open: boolean
  filterStatus?: ProductStatusCode[]
  setProducts: (product: ProductType[]) => void
  close: () => void
}> = ({ open, filterStatus = [], isSearchable, isMultiSelectable = true, setProducts, close }) => {
  const [searchParam, setSearchParam] = useState("id")
  const [searchKeyword, setSearchKeyword] = useState("")
  const [globalSale, setGlobalSale] = useState<boolean>(false)
  const [sort, setSort] = useState<string>("")

  const [allProducts, setAllProducts] = useState<ProductType[]>([])
  const [paginationParams, setPaginationParams] = useState<PaginationParamsType>({
    number: 0,
    totalElements: 0,
    totalPages: 1,
    first: true,
    size: FETCH_LIST_COUNT,
  })

  const columns = useMemo(
    () => [
      { field: "id", headerName: "ID", width: 60 },
      {
        field: "startSaleAt",
        headerName: "등록일시",
        width: 100,
        renderCell: (params: any) => (
          <div style={{ textAlign: "center", lineHeight: 0.6 }}>
            <p>{toDateStr(params.row.startSaleAt, false)}</p>
            <p>{toTimeStr(params.row.startSaleAt)}</p>
          </div>
        ),
      },
      {
        field: "status",
        headerName: "상태",
        sortable: false,
        width: 90,
        renderCell: (params: any) => (
          <div style={{ textAlign: "center", lineHeight: 0.6 }}>
            <p style={{ color: params.row.status === "판매중" ? "#2e7d32" : "#d32f2f", fontWeight: 700 }}>
              {params.row.status}
            </p>
            <p style={{ color: params.row.onDisplay ? "#2e7d32" : "#d32f2f" }}>
              {params.row.onDisplay ? "노출" : "숨김"}
            </p>
          </div>
        ),
      },
      {
        field: "thumbnail",
        headerName: "썸네일",
        sortable: false,
        width: 100,
        renderCell: (params: any) => (
          <Box>
            <img style={{ width: "100%" }} src={params.row.thumbnail} alt={""} />
          </Box>
        ),
      },
      {
        field: "title",
        headerName: "상품명",
        flex: 1,
        sortable: false,
        renderCell: (params: any) => (
          <div style={{ lineHeight: 0.6 }}>
            <p>
              {params.row.saleType} / {params.row.productCondition}
            </p>
            <p style={{ fontSize: "16px", fontWeight: 700 }}>{params.row.title}</p>
            <p>{params.row.titleSecondary}</p>
            <p>{params.row.detailModel.fullRefNo}</p>
          </div>
        ),
      },
      {
        field: "price",
        headerName: "가격",
        sortable: false,
        width: 100,
        valueGetter: (params: any): string => `${currencyFormat(params.row.price)}`,
      },
      {
        field: "globalSale",
        headerName: "해외판매",
        sortable: false,
        width: 80,
        valueGetter: (params: any): string => `${params.row.globalSale ? "Y" : "N"}`,
      },
      {
        field: "priceDisparityRate",
        headerName: "괴리율",
        sortable: false,
        width: 80,
        valueGetter: (params: any) => `${params.row.priceDisparityRate ?? "-"}`,
      },
    ],
    []
  )

  const [productList, setProductList] = useState<ProductType[]>([])
  const [selectionProductIds, setSelectionProductIds] = useState<ProductType["id"][]>([])

  const fetchProductSimpleList = useCallback(
    (params: { pageNumber?: number; sort?: string }) => {
      getProductSimpleList({
        status: filterStatus?.join(""),
        page: params.pageNumber,
        size: FETCH_LIST_COUNT,
        ...(searchKeyword ? { [searchParam]: searchKeyword } : {}),
        globalSale: globalSale,
        sort: params.sort,
      }).then(({ content = [], totalElements = 0, totalPages = 0, first, size = FETCH_LIST_COUNT, number = 0 }) => {
        setAllProducts((allProducts) => uniqBy([...allProducts, ...content], (product) => product.id))
        setProductList(content)
        setPaginationParams((prev) => ({ ...prev, totalElements, totalPages, first, size, number }))
      })
    },
    [filterStatus, searchParam, searchKeyword, globalSale]
  )

  const changePageNumber = useCallback(
    (pageNumber: number) => {
      setPaginationParams((prev) => ({ ...prev, number: pageNumber }))
      fetchProductSimpleList({ pageNumber, sort })
    },
    [searchParam, searchKeyword, sort, fetchProductSimpleList]
  )

  const changeSortOption = useCallback(
    (sort: string) => {
      setSort(sort)
      fetchProductSimpleList({ pageNumber: 0, sort })
    },
    [searchParam, searchKeyword, fetchProductSimpleList]
  )

  const onSearch = () => {
    fetchProductSimpleList({ pageNumber: 0 })
  }

  useEffect(() => {
    if (!open) return

    fetchProductSimpleList({ pageNumber: 0 })
  }, [open])

  useEffect(() => {
    fetchProductSimpleList({ pageNumber: 0 });
  }, [globalSale]);

  return (
    <Dialog open={open} maxWidth={"lg"} style={{ zIndex: 10000 }}>
      <TitleWrapper>
        <DialogTitle>상품 선택</DialogTitle>
        {isSearchable && (
          <ProductIdSearchBar>
            <SearchBar>
              <FormControlLabel label={'해외 판매 상품 모아보기'} control={
                <Checkbox
                  checked={globalSale == true}
                  onChange={(e) => {
                    setGlobalSale(e.target.checked)
                  }}/>
              }/>
              <FormControl style={{ minWidth: "150px" }}>
                <Select
                  size={"small"}
                  defaultValue={searchParam}
                  required
                  onChange={(e) => {
                    setSearchParam(e.target.value)
                    setSearchKeyword("")
                  }}
                  MenuProps={{
                    style: { zIndex: 20000 },
                  }}
                >
                  {searchMenuList.map((menu) => (
                    <MenuItem value={menu.value.toString()} key={menu.value}>
                      {menu.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <SearchInput
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSearch()
                  }
                }}
              />
            </SearchBar>
          </ProductIdSearchBar>
        )}
      </TitleWrapper>
      <DialogContent style={{ width: "900px" }}>
        <Stack direction={"column"} spacing={1}>
          <Box height={"500px"}>
            <DataGrid
              pagination
              paginationMode="server"
              page={paginationParams.number}
              pageSize={FETCH_LIST_COUNT}
              onPageChange={changePageNumber}
              style={{ width: "100%", height: "100%" }}
              columns={columns}
              rows={productList}
              rowCount={paginationParams.totalElements}
              getRowHeight={() => 100}
              checkboxSelection={isMultiSelectable}
              onSelectionModelChange={(ids: any) => setSelectionProductIds(ids)}
              keepNonExistentRowsSelected
              onSortModelChange={(model) => {
                changeSortOption(`${model[0].field},${model[0].sort}`)
              }}
              getRowId={(row) => row.id}
              selectionModel={selectionProductIds}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          size={"small"}
          color={"primary"}
          onClick={async () => {
            const selectionProducts = allProducts.filter((product) => selectionProductIds.includes(product.id))
            setProducts(selectionProducts)
            close()
          }}
        >
          확인
        </Button>
        <Button variant={"contained"} size={"small"} color={"error"} onClick={() => close()}>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProductSelectPopup

const TitleWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const ProductIdSearchBar = styled.div`
  padding-right: 24px;
`
