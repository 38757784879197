import { ShippingStatusCode, ShippingType } from "./ShippingType"
import { ProductType, SaleTypeCode } from "./ProductType"
import {
  AccessoriesConditionCode,
  DiagnosisResultCode,
  DiagnosisType,
  OfficialGuaranteeCode,
  OriginalBoxConditionCode,
  ProductConditionCode,
} from "./DiagnosisType"
import { UserType } from "./UserType"
import { BuyOrderType } from "./BuyOrderType"
import { AdminUserType } from "./AdminUserType"

export const enum SellOrderStatusCode {
  픽업_준비 = "픽업 준비",
  입고중 = "입고중",
  입고_완료 = "입고 완료",
  진단중 = "진단중",
  진단_완료 = "진단 완료",
  판매_대기 = "판매 대기",
  판매중 = "판매중",
  결제_대기 = "결제 대기",
  판매_완료 = "판매 완료",
  페널티_대기 = "페널티 대기",
  판매_취소 = "판매 취소",
  판매_포기 = "판매 포기",
  판매_불합격 = "판매 불합격",
  반려 = "반려",
}

export const enum SellOrderStatusGroupType {
  진단중 = "진단중",
  판매중 = "판매중",
  판매_완료 = "판매 완료",
  진단_위탁 = "진단_위탁",
  진단_진단전 = "진단_진단전",
}

export const enum OrderType {
  위탁 = "위탁",
  직매입 = "직매입",
  재매입 = "재매입",
  진단전 = "진단전",
}

export const enum SellOrderProductType {
  바이버 = "바이버",
  서울옥션_바이버위탁 = "서울옥션_바이버위탁",
  서울옥션_서울옥션위탁 = "서울옥션_서울옥션위탁",
}

export const enum ShippingMethodType {
  안전배송 = "안전배송",
  쇼룸방문 = "쇼룸방문",
  안전배송_2 = "안전배송_2",
}

export declare interface SellOrderOnlyIdType {
  id?: string
}

export type CreateSellOrderRequestBodyType = (
  | {
      sellOrderType: OrderType.직매입
      skipDiagnosis: boolean
    }
  | {
      sellOrderType: OrderType.위탁
      userId: number
      shippingMethod: ShippingMethodType
      createShipping?: {
        name: string
        phone: string
        zipCode: string
        address: string
        addressDetail: string
      }
    }
  | {
      sellOrderType: OrderType.진단전
      userId: number
      shippingMethod: ShippingMethodType
      createShipping?: {
        name: string
        phone: string
        zipCode: string
        address: string
        addressDetail: string
      }
      selfCheck: {
        stab: "있음" | "없음"
        glassScratch: "있음" | "없음"
        braceletLinks: "모두 있음" | "1개 부족" | "2개 부족" | "3개 부족" | "4개 이상 부족" | "모름" | "해당 없음"
        protectingFilm: "있음" | "없음"
      }
      gallery: { id: number }[]
      userDescription: string
      productTitle: string
    }
) & {
  sellOrderProductType: SellOrderProductType
  detailModelId: number | null
  brandSid: string | null
  price: number
  mainProductCondition: ProductConditionCode
  officialGuarantee: OfficialGuaranteeCode
  accessoriesCondition: AccessoriesConditionCode
  originalBoxCondition: OriginalBoxConditionCode
  accessories: {
    outerBox: boolean | null
    innerBox: boolean | null
    coscCert: boolean | null
    userManual: boolean | null
    guaranteeManual: boolean | null
    guaranteeCase: boolean | null
    medal: boolean | null
    etc: boolean | null
  }
  stampingYear: number | null
  globalSale: boolean | null
}

export declare interface CreateSellOrderFormType {
  sellOrderType: OrderType | null
  userId: number | null
  shippingMethod: ShippingMethodType | null
  createShipping: {
    name: string
    phone: string
    zipCode: string
    address: string
    addressDetail: string
  } | null
  detailModelId: number | null
  sellOrderProductType: SellOrderProductType
  brandSid: string | null
  price: number
  mainProductCondition: ProductConditionCode | null
  officialGuarantee: OfficialGuaranteeCode | null
  accessoriesCondition: AccessoriesConditionCode | null
  originalBoxCondition: OriginalBoxConditionCode | null
  accessories: {
    outerBox: boolean | null
    innerBox: boolean | null
    coscCert: boolean | null
    userManual: boolean | null
    guaranteeManual: boolean | null
    guaranteeCase: boolean | null
    medal: boolean | null
    etc: boolean | null
  }
  stampingYear: number | null
  selfCheck: {
    stab: "있음" | "없음" | null
    glassScratch: "있음" | "없음" | null
    braceletLinks: "모두 있음" | "1개 부족" | "2개 부족" | "3개 부족" | "4개 이상 부족" | "모름" | "해당 없음" | null
    protectingFilm: "있음" | "없음" | null
  }
  gallery: { id: number; url: string }[]
  userDescription: string
  productTitle: string
  globalSale: boolean | null
}

export declare interface SellOrderType {
  id?: string
  sellOrderType?: OrderType
  seller?: UserType
  product?: ProductType
  buyOrder?: BuyOrderType
  previousProduct?: ProductType
  productDiagnosis?: DiagnosisType
  // paymentMethod?: EasyPaymentMethodDto.Response.Public,
  shipping?: ShippingType
  // settlement?: SettlementDto.Response.Public,
  sellOrderStatus?: SellOrderStatusCode

  acceptedAt?: string
  deliveryRequestedAt?: string
  // deliveryRequestedBy?: string,
  waitingDiagnosisAt?: string
  // waitingDiagnosisBy?: string,
  diagnosingAt?: string
  // diagnosingBy?: string,
  completeDiagnosisAt?: string
  // completeDiagnosisBy?: string,
  waitingSaleAt?: string
  // waitingSaleBy?: string,
  onSaleAt?: string
  // onSaleBy?: string,
  completePaymentAt?: string
  completeSaleAt?: string
  cancelSaleAt?: string
  underqualifiedAt?: string
  productPrice?: number
  exhibitionSale?: boolean
  globalSale?: boolean
  shippingFee?: number
  diagnosisFee?: number
  sellingFee?: number
  sellingFeeRate?: number
  totalAmount?: number
  upgradeFee?: number
  sellingManagementFee?: number
  settlementAmount?: number
  couponProductDiscountAmount?: number
  couponShippingFeeDiscountAmount?: number
  couponSellingFeeDiscountAmount?: number
  couponDiagnosisFeeDiscountAmount?: number
  shippingFeeDiscountAmount?: number
  membershipDiscountAmount?: number
  pointAmount?: number
  paymentAmount?: number
  orderedAt?: string
  repurchasedProduct?: ProductType
  provisionalPreSettlement?: {
    id: number
    orderId: string
    preSettlementPrice: number
  }
  confirmedPreSettlement?: {
    id: number
    orderId: string
    standardPriceForPreSettlement?: number
    preSettlementPrice: number
    payoutDate?: string
  }
  rejectHistory?: SellOrderRejectHistory[]
}

export type SellOrderListSearchParams = {
  productId?: string
  startDate?: string
  endDate?: string
  diagnosisResults?: DiagnosisResultCode[]
  status?: SellOrderStatusCode[]
  shippingStatusCode?: ShippingStatusCode[]
  productSaleType?: SaleTypeCode
  sellOrderType?: OrderType[]
  sellerName?: string
  sellerId?: string
  sellerPhone?: string
  productTitle?: string
  needNotify?: string
  page?: number
  size?: number
  sort?: string
}

export declare interface SellOrderBatchUploadResultType {
  totalCount: number
  succeedCount: number
  failureCount: number
  dataList: SellOrderOriginCellValue[]
}

export declare interface SellOrderOriginCellValue {
  userId?: number
  sellOrderType?: string
  shippingMethod?: string
  brandSid?: string
  modelName?: string
  detailModelName?: string
  reference?: string
  detailModel?: string
  productTitle?: string
  price?: number

  mainProductCondition?: string
  officialGuarantee?: string
  originalBoxCondition?: string
  accessoriesCondition?: string
  stampingYear?: number
  stampingMonth?: number
  exhibitionSale?: string
  stab?: string
  glassScratch?: string
  braceletLinks?: string
  protectingFilm?: string

  repImage?: string
  detailImage1?: string
  detailImage2?: string
  detailImage3?: string
  detailImage4?: string
  detailImage5?: string
  userDescription?: string
  globalSale?: string

  status: string
  error?: string
}

export type SellOrderExcelParams = {
  startDate: String
  endDate: String
  types: OrderType[]
  statusGroup: SellOrderStatusGroupType
}

export type PreProductsExcelParams = {
  startDate: String
  endDate: String
  sellOrderTypes: OrderType[]
  sellOrderStatus: SellOrderStatusCode[]
  diagnosisResults: DiagnosisResultCode[]
}

export const enum SellOrderSaleChannelCode {
  바이버 = "바이버",
  서울옥션_바이버위탁 = "서울옥션_바이버위탁",
  서울옥션_서울옥션위탁 = "서울옥션_서울옥션위탁",
}

export type SellOrderRejectHistory = {
  id: number
  rejectReasonType: string
  rejectReasonDetail: string
  rejectedAt?: string
  rejectedBy?: AdminUserType
}
