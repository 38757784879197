import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select, Switch,
  TextField,
  Typography,
} from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import { SellOrderStatusCode, SellOrderType } from "types/SellOrderType"
import { numberWithCommas, toRange, truncate } from "utils/NumberUtils"
import { updateShippingMethod, updateTrackingNo } from "apis/shippingAPI"
import { getResellPrice } from "apis/estimationAPI"
import { ResellPriceResponse } from "types/EstimationType"
import { toast } from "react-toastify"
import SearchDetailModelPopup from "components/Popup/SearchDetailModelPopup"
import { getSellOrder, updateSellOrder } from "apis/sellOrderAPI"
import SellOrderInformation from "../EditPopup/SellOrderInformation"
import { ShippingMethod } from "../../../types/ShippingMethod"
import { updateReceiveBuyPriceOffer } from "../../../apis/productAPI"

type ViewSellOrderPopupProps = {
  open: boolean
  selectedSellOrder?: SellOrderType
  handleClose: Function
}

const ViewGuaranteedSellOrderPopup: React.FC<ViewSellOrderPopupProps> = ({ open, selectedSellOrder, handleClose }) => {
  const [sellOrder, setSellOrder] = useState<SellOrderType | undefined>(selectedSellOrder)
  const [estimatedPrice, setEstimatedPrice] = useState<ResellPriceResponse>({})
  const [trackingNo, setTrackingNo] = useState<string>()
  const [selectPopupOpen, setSelectPopupOpen] = useState<boolean>(false)
  const [shippingMethod, setShippingMethod] = useState<ShippingMethod>()
  const [receiveBuyPriceOffer, setReceiveBuyPriceOffer] = useState<boolean>(false)

  const updateDetailModelId = (detailModelId: number) => {
    // @ts-ignore
    updateSellOrder(sellOrder?.id, { detailModelId: detailModelId }).then((res) => {
      setSellOrder(res)
      updateEstimatedPrice(res)
    })
  }
  const updateShipping = (event: any) => {
    if (sellOrder?.shipping?.id && trackingNo) {
      updateTrackingNo(sellOrder.shipping.id, { trackingNo: trackingNo })
        .then((shipping) => {
        })
        .catch((error) => {
          toast.error(`운송장번호 갱신 실패 (${error.message || error})`)
        })
    } else {
      toast.error(`배송정보를 찾을 수 없습니다. 관리자에게 문의하세요.`)
    }
  }
  const updateEstimatedPrice = (sellOrder?: SellOrderType) => {
    getResellPrice({
      // @ts-ignore
      detailModel: { id: sellOrder?.product?.detailModel?.id },
      mainProductCondition: sellOrder?.product?.sellerProvidedProductInfo?.productCondition,
      accessoriesCondition: sellOrder?.product?.sellerProvidedProductInfo?.accessoriesCondition,
      officialGuarantee: sellOrder?.product?.sellerProvidedProductInfo?.officialGuarantee,
      stampingYear: sellOrder?.product?.sellerProvidedProductInfo?.stampingYear,
      originalBoxCondition: sellOrder?.product?.sellerProvidedProductInfo?.originalBoxCondition,
    }).then((res) => setEstimatedPrice(res))
  }
  const onUpdateReceiveBuyPriceOffer = async (receiveBuyPriceOffer: boolean) => {
    await updateReceiveBuyPriceOffer(sellOrder?.product!!.id!!, receiveBuyPriceOffer).then(async () => {
      toast.success(receiveBuyPriceOffer ? "구매 입찰 제안 받기 기능이 설정되었습니다." : "구매 입찰 제안 받기 기능이 해제되었습니다.")
      setReceiveBuyPriceOffer(receiveBuyPriceOffer)
    }).catch((error) => {
      toast.error(`${error.message || error}`)
    })
  }

  useEffect(() => {
    if (open) {
      setSelectPopupOpen(false)

      getSellOrder(selectedSellOrder?.id!)
        .then((order) => {
          updateEstimatedPrice(order)
          setSellOrder(order)
          setTrackingNo(order?.shipping?.trackingNo)
          setShippingMethod(order.shipping?.shippingMethod)
          setReceiveBuyPriceOffer(order?.product?.receiveBuyPriceOffer!!)
        })
        .catch((error) => {
          toast.error(`주문조회 실패 (${error.message || error})`)
        })
    } else {
      setSelectPopupOpen(false)
      setSellOrder(undefined)
      setEstimatedPrice({})
      setTrackingNo(undefined)
      setReceiveBuyPriceOffer(false)
    }
  }, [open, selectedSellOrder?.id])

  const getMaxResellPrice = (rate?: number, price?: number) => {
    if (!rate || !price) {
      return "-"
    }

    return numberWithCommas(rate * price)
  }

  const isSoldOutSellOrder = useMemo(() => {
    return selectedSellOrder?.sellOrderStatus === SellOrderStatusCode.판매_완료
  }, [selectedSellOrder?.sellOrderStatus])

  const handleShippingMethod = () => {
    const shippingId = sellOrder?.shipping?.id

    if (
      window.confirm(`판매주문번호 [${sellOrder?.shipping?.id}]의 입고 방식을 ${shippingMethod}로 변경하시겠습니까?`)
    ) {
      if (typeof shippingId !== "undefined" && typeof shippingMethod !== "undefined") {
        updateShippingMethod(shippingId, shippingMethod)
          .then((res) => {
            setSellOrder((sellOrder) => ({
              ...sellOrder,
              shipping: res,
            }))
          })
          .catch((ex) => {
            toast.error(ex.message)
          })
      }
    }
  }

  return (
    <>
      <SearchDetailModelPopup
        open={selectPopupOpen}
        // @ts-ignore
        handleClose={(detailModel) => {
          setSelectPopupOpen(false)
          updateDetailModelId(detailModel?.id)
        }}
      />
      <Dialog open={open} maxWidth={"xl"} fullWidth>
        <DialogTitle>
          <Typography fontWeight={"bold"} fontSize={"30px"}>
            재매입 판매 주문 정보
          </Typography>
        </DialogTitle>
        <DialogContent>
          <SellOrderInformation sellOrder={selectedSellOrder} />

          <div style={{ display: "flex", alignItems: "center" }}>
            <h2>상품 정보</h2>&nbsp;&nbsp;&nbsp;&nbsp;
            <Button variant={"outlined"} size={"small"} color={"error"} onClick={() => setSelectPopupOpen(true)}>
              상세모델 변경
            </Button>
          </div>
          <table className={"basic"}>
            <tbody>
            <tr>
              <td className={"ta-center"} rowSpan={isSoldOutSellOrder ? 9 : 7}>
                <img
                  style={{ display: "block", margin: "auto", width: "200px", height: "300px", objectFit: "cover" }}
                  src={sellOrder?.product?.thumbnail}
                  alt=""
                />
              </td>
              <td className={"header"} colSpan={3}>
                상품명
              </td>
              <td className={"header"} colSpan={1}>
                Ref No.
              </td>
            </tr>
            <tr>
              <td colSpan={1}> {sellOrder?.product?.brandSid}</td>
              <td colSpan={1}>{sellOrder?.product?.title}</td>
              <td colSpan={1}>{sellOrder?.product?.titleSecondary}</td>
              <td colSpan={1}>{sellOrder?.product?.detailModel?.fullRefNo}</td>
            </tr>
            <tr>
              <td className={"header"} colSpan={4}>
                가격 정보
              </td>
            </tr>
            <tr>
              <td className={"header"} colSpan={1}>
                현재 시장 가격범위 (감가 미적용)
              </td>
              <td className={"ta-center"} colSpan={1}>{`${toRange(
                estimatedPrice?.marketPrice?.min,
                estimatedPrice?.marketPrice?.max,
                4,
              )} 만원`}</td>
              <td className={"header"} colSpan={1}>
                최근 30일간 시세 볼륨
              </td>
              <td className={"ta-center"} colSpan={1}>
                {estimatedPrice.marketVolume}
              </td>
            </tr>
            <tr>
              <td className={"header"} colSpan={1}>
                판매신청 당시 예상 가격범위
              </td>
              <td className={"ta-center"} colSpan={1}>{`${toRange(
                sellOrder?.productDiagnosis?.viverPrices?.estimatedPriceAtOrder?.min,
                sellOrder?.productDiagnosis?.viverPrices?.estimatedPriceAtOrder?.max,
                4,
              )} 만원`}</td>
              <td className={"header"} colSpan={1}>
                페널티 기준가
              </td>
              <td className={"ta-center"} colSpan={1}>{`${numberWithCommas(
                truncate(sellOrder?.product?.initialPrice, 4),
              )} 만원`}</td>
            </tr>
            <tr>
              <td className={"header"} colSpan={1}>
                현재 예상 가격범위
              </td>
              <td className={"ta-center"} colSpan={1}>{`${toRange(
                estimatedPrice?.correctedEstimatedPrice?.min,
                estimatedPrice?.correctedEstimatedPrice?.max,
                4,
              )} 만원`}</td>
              <td className={"header"} colSpan={1}>
                고객 판매 희망가
              </td>
              <td className={"ta-center"} colSpan={1}>{`${numberWithCommas(
                truncate(sellOrder?.product?.price, 4),
              )} 만원`}</td>
            </tr>
            {isSoldOutSellOrder && (
              <>
                <tr>
                  <td className={"header"} colSpan={1}>
                    이전 상품 구매가
                  </td>
                  <td className={"ta-center"} colSpan={1}>{`${numberWithCommas(
                    sellOrder?.repurchasedProduct?.initialPrice,
                  )} 원`}</td>
                  <td className={"header"} colSpan={1}>
                    최대 매입 금액{" "}
                    {`${
                      sellOrder?.repurchasedProduct?.repurchaseGuaranteeRate
                        ? `(${sellOrder?.repurchasedProduct?.repurchaseGuaranteeRate * 100}%)`
                        : ""
                    }`}
                  </td>
                  <td className={"ta-center"} colSpan={1}>
                    {getMaxResellPrice(
                      sellOrder?.repurchasedProduct?.repurchaseGuaranteeRate,
                      sellOrder?.repurchasedProduct?.initialPrice,
                    )}{" "}
                    원
                  </td>
                </tr>
                <tr>
                  <td className={"header"} colSpan={1}>
                    판매가
                  </td>
                  <td className={"ta-center"}>{numberWithCommas(sellOrder?.product?.price)} 원</td>
                </tr>
              </>
            )}

            <tr>
              <td className={"header"}>구매입찰 제안 받기</td>
              <td>
                <Switch
                  checked={receiveBuyPriceOffer}
                  onChange={(_, checked) => onUpdateReceiveBuyPriceOffer(checked)}
                />
              </td>
            </tr>
            </tbody>
          </table>

          <h2>상품상태</h2>
          <table className={"basic"}>
            <tbody>
            <tr>
              <td className={"header"}>제품상태</td>
              <td className={"header"}>보증서</td>
              <td className={"header"}>정품 박스</td>
              <td className={"header"}>부속품</td>
              <td className={"header"}>스탬핑 연도</td>
            </tr>
            <tr>
              <td>{selectedSellOrder?.product?.sellerProvidedProductInfo?.productCondition}</td>
              <td>{selectedSellOrder?.product?.sellerProvidedProductInfo?.officialGuarantee}</td>
              <td>{selectedSellOrder?.product?.sellerProvidedProductInfo?.originalBoxCondition}</td>
              <td>{selectedSellOrder?.product?.sellerProvidedProductInfo?.accessoriesCondition}</td>
              <td>{selectedSellOrder?.product?.sellerProvidedProductInfo?.stampingYear}</td>
            </tr>
            </tbody>
          </table>

          <h2>정산 정보</h2>
          <table className={"basic"}>
            <tbody>
            <tr>
              <td className={"header"}>판매가</td>
              <td className={"header"}>거래 수수료</td>
              <td className={"header"}>주문관리 수수료</td>
              <td className={"header"}>배송비</td>
              <td className={"header"}>선정산 금액</td>
            </tr>
            <tr>
              <td>{numberWithCommas(sellOrder?.product?.price)}원</td>
              <td>{numberWithCommas(sellOrder?.sellingFee ?? 0)}원</td>
              <td>{numberWithCommas(sellOrder?.sellingManagementFee ?? 0)}원</td>
              <td>{numberWithCommas(sellOrder?.shippingFee ?? 0)}원</td>
              <td>
                {sellOrder?.confirmedPreSettlement?.preSettlementPrice
                  ? numberWithCommas(sellOrder.confirmedPreSettlement.preSettlementPrice)
                  : 0}
                원
              </td>
            </tr>
            <tr>
              <td colSpan={4} className={"no-border"}></td>
              <td colSpan={1} className={"header"}>
                총 정산 금액
              </td>
            </tr>
            <tr>
              <td colSpan={4} className={"no-border"}></td>
              <td>{numberWithCommas(sellOrder?.settlementAmount ?? 0)}원</td>
            </tr>
            </tbody>
          </table>

          <h2>배송 정보</h2>
          <table className={"basic"}>
            <tbody>
            <tr>
              <td className={"header"} colSpan={2}>
                운송송장번호
              </td>
              <td className={"header"}>배송상태</td>
              <td className={"header"}>배송타입</td>
              <td className={"header"} colSpan={2}>
                입고방식
              </td>
              <td className={"header"}>이름</td>
              <td className={"header"}>연락처</td>
              <td className={"header"} colSpan={2}>
                배송지 주소
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <TextField
                  placeholder={"운송장번호"}
                  size={"small"}
                  value={trackingNo}
                  onChange={(event) => setTrackingNo(event.target.value)}
                />
                <Button variant={"outlined"} onClick={updateShipping}>
                  {trackingNo ? "변경" : "입력"}
                </Button>
              </td>
              <td>{selectedSellOrder?.shipping?.shippingStatus}</td>
              <td>{selectedSellOrder?.shipping?.shippingType}</td>
              <td colSpan={2}>
                <Select<ShippingMethod>
                  size={"small"}
                  value={shippingMethod || ""}
                  onChange={(params) => {
                    setShippingMethod(params.target.value as ShippingMethod)
                  }}
                >
                  <MenuItem value={"안전배송"}>발렉스</MenuItem>
                  <MenuItem value={"안전배송_2"}>일양택배</MenuItem>
                  <MenuItem value={"쇼룸방문"}>쇼룸방문</MenuItem>
                  <MenuItem value={"일반택배"}>일반택배</MenuItem>
                  <MenuItem value={"해외배송_DHL"}>DHL</MenuItem>
                </Select>
                <Button
                  variant={"outlined"}
                  onClick={handleShippingMethod}
                  disabled={sellOrder?.shipping?.shippingMethod === shippingMethod}
                >
                  수정
                </Button>
              </td>
              <td>{selectedSellOrder?.shipping?.name}</td>
              <td>{selectedSellOrder?.shipping?.phone}</td>
              <td colSpan={2}>
                {selectedSellOrder?.shipping?.address} {selectedSellOrder?.shipping?.addressDetail}
              </td>
            </tr>
            <tr>
              <td className={"header"} colSpan={10}>
                배송 추적
              </td>
            </tr>
            {selectedSellOrder?.shipping?.journey?.length ? (
              selectedSellOrder?.shipping?.journey?.map((it) => (
                <tr>
                  <td colSpan={4}>{it.when}</td>
                  <td colSpan={4}>{it.how}</td>
                  <td colSpan={3}>{it.where}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={10}>배송정보가 없습니다</td>
              </tr>
            )}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button size={"medium"} variant={"outlined"} color={"error"} onClick={() => handleClose()}>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ViewGuaranteedSellOrderPopup
